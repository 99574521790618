import React, { useEffect, useState } from "react";
import { FiUsers, FiMail, FiClock } from "react-icons/fi";
import client from "../../api/client";
import HeaderTitle from "../../components/common/HeaderTitle";

function DeletedUsers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get("api/Dashboard/deleted-users");
        setData(response.data.data);
      } catch (e) {
        setError("Failed to fetch deleted users");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderTitle title="Deleted Users" />
      {loading && <p className="text-center text-gray-600">Loading...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}
      {!loading && !error && (
        <div className="bg-white shadow-lg rounded-lg p-6 mt-4">
          {data.length > 0 ? (
            <ul className="divide-y divide-gray-300">
              {data.map((user) => (
                <li
                  key={user.id}
                  className="py-4 px-6 flex items-center space-x-4 hover:bg-gray-50 rounded-lg"
                >
                  <FiUsers className="text-blue-500 text-xl" />
                  <div className="flex-1">
                    <p className="font-semibold text-lg">{user.name}</p>
                    <p className="text-gray-600 flex items-center">
                      <FiMail className="mr-2" />
                      {user.email}
                    </p>
                    <p className="text-gray-600 flex items-center">
                      {user.displayName}
                    </p>
                    <p className="text-sm text-gray-400 flex items-center">
                      <FiClock className="mr-2" />
                      Deleted At: {user.deletedAt.split("T")[0]}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-center text-gray-500">No deleted users found.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default DeletedUsers;
