import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminAccountImage from "../../assets/images/adminAccount.png";
import dashBoardAccount from "../../assets/images/dashboardIcon.png";
import preview from "../../assets/images/preview.png";
import setting from "../../assets/images/Setting.png";
import monyTransfers from "../../assets/images/transactions.png";
import users from "../../assets/images/users.png";
import logo from "../../assets/images/logo.png";
import profile from "../../assets/images/Profile photo.png";
import logout from "../../assets/images/Logout.png";
import NavItem from "./NavItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "./CustomBtn";
import Swal from "sweetalert2";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Get user data from localStorage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUserData(JSON.parse(storedUser)); // Parse the stored data
    }
  }, []);
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("authToken");
        navigate("/login");

        Swal.fire(
          "Logged Out!",
          "You have been logged out successfully.",
          "success"
        );
      }
    });
  };

  return (
    <div className="relative">
      {/* Sidebar Hamburger Button */}
      <button
        onClick={toggleSidebar}
        className="fixed top-4 left-4 z-50 p-2 bg-gray-100 rounded-md shadow-md lg:hidden"
      >
        <div className="space-y-1">
          <span className="block w-6 h-0.5 bg-gray-600"></span>
          <span className="block w-6 h-0.5 bg-gray-600"></span>
          <span className="block w-6 h-0.5 bg-gray-600"></span>
        </div>
      </button>

      {/* Sidebar Container */}
      <div
        className={`fixed bottom-0 top-0 left-0 bg-white shadow-sm transition-transform duration-300 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 h-full w-64 z-30 flex flex-col`}
      >
        {/* Logo */}
        <div className="flex items-center justify-center p-4">
          <img src={logo} className="w-24" alt="App Logo" />
        </div>

        {/* Navigation Items */}
        <ul className="flex flex-col py-8 text-primaryText items-center justify-center gap-3">
          <NavItem imgSrc={dashBoardAccount} text="Dashboard" to="/" />
          <NavItem imgSrc={preview} text="Under Review" to="/under-review" />
          <NavItem imgSrc={users} text="Users" to="/users" />
          <NavItem imgSrc={users} text="Deleted Users" to="/deleted-users" />
          <NavItem
            imgSrc={
              "https://static-00.iconduck.com/assets.00/chat-icon-2048x2048-i7er18st.png"
            }
            text="Chats"
            to="/chat"
          />
          <NavItem
            imgSrc={monyTransfers}
            text="Transactions"
            to="/transactions"
          />
          <NavItem
            imgSrc={AdminAccountImage}
            text="Admin Account"
            to="/admin-account"
          />
          <NavItem
            imgSrc={monyTransfers}
            text="Money Transfers"
            to="/money-transfers"
          />
          {/* <NavItem imgSrc={setting} text="Settings" to="/settings" /> */}
        </ul>

        {/* User Profile and Logout */}
        <div className="mt-auto px-1 pb-6">
          {userData ? (
            <div className="flex items-center gap-1">
              <img
                src={userData.image || profile} // Replace with a default if no profile picture
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div className="text-[13px]">
                <h4 className="font-semibold">
                  {userData.username || "Unknown User"}
                </h4>
                <p className="text-gray-500">
                  {userData.email || "No email provided"}
                </p>
              </div>
              <button onClick={handleLogout}>
                <img src={logout} alt="Logout" />{" "}
                {/* Replace with actual logout icon */}
              </button>
            </div>
          ) : (
            <p>Loading user data...</p>
          )}
        </div>
      </div>

      {/* Logout Confirmation Dialog */}
    </div>
  );
};

export default Sidebar;
